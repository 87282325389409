<template>
    <!-- style="max-width: 1100px; margin: auto; font-size: 1.5rem;" -->
  <div style="margin: auto; font-size: 1.5rem;" class="grey lighten-3" >
  <v-app>

    <v-flex xs12>
    <v-toolbar color="pink" dark >

      <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->
      <v-toolbar-title><router-link to="/">RESUME</router-link> <router-link to="/"><small>https://i.ch2n.com/#/en</small></router-link></v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn> -->
    </v-toolbar>
    </v-flex>

    <v-card>
      <v-container fluid grid-list-lg >

        <v-layout row wrap>

          <v-flex >

            <v-card color="blue-grey darken-2" class="white--text">

              <v-layout row style="word-break: break-word;">

                <v-flex >
                  <v-card-title primary-title>
                    <div>
                      <div class="title font-weight-medium mb-1" >Carlos Chen</div>
                      <div>overall 8 years experience</div>
                      <div>i@ch2n.com</div>
                      <div>+86-131-2287-9925</div>
                    </div>
                  </v-card-title>
                </v-flex>

                <!-- <v-flex xs3></v-flex> -->

                    <!-- src="https://i.loli.net/2020/05/31/NCe6DnU2xoQa4dY.jpg" -->
                <v-contianer align-end>
                <v-flex mt-1 mr-2>
                  <v-img
                  class="align-end mt-2"
                    src="../assets/Untitled.png"
                    height="145px"
                    width="145px"
                    contain
                  ></v-img>
                </v-flex>
                </v-contianer>

              </v-layout>

              <v-divider light></v-divider>

              <v-card-actions class="pa-3">
                <v-layout row>
                  <v-flex>
                    <div>An SRE good at Linux, Python, Kubernetes, Splunk and GCP</div>
                  </v-flex>
                  <!-- <v-flex class="xs5">
                    <div>Shanghai Polytechnic University</div>
                  </v-flex> -->
                  <!-- <v-flex class="xs4">
                    <div>majored in Electronic Commerce</div>
                  </v-flex> -->
                </v-layout>
              </v-card-actions>

            </v-card>
          </v-flex>


          <v-flex xs12>
            <v-card  style="word-break: break-word;">
            
              <v-card-title primary-title>
                <div style="line-height: 1.25 !important">

                <v-flex class="mb-0 pb-0 mt-0 pt-0">
                  <h3 class="headline mb-0 pb-0 blue--text">Tech Stack Overview</h3>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Proficient in Linux: </span> Immersed in the terminal environment every day with <span class="font-weight-bold">vim, tmux, mosh and other utilities</span>, 
                      able to build a minimal Linux distribution by following the <a class="blue-grey--text text--lighten-1" href="https://www.linuxfromscratch.org/" target="_blank">Linux From Scratch</a> project.
                      Have to admit that I'm a <span class="font-weight-bold">clang and kernel-illiterate</span>, never an expert of perf, eBPF BCC tools and /proc file system,
                      and have nearly zero experience of performance tunning in computing, networking, memory and I/O.
                      Despite these limitations, I'd named myself as a <b>command-line hero</b>, with a level of familiarity unparalleled among peers.
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Expert of Splunk: </span> Several years of expertise in Splunk, serving either as cluster admin or a power user.
                      Able to establish cluster from zero, manage numerous configutations for all the components, develop extention APP, create interactive dashboards,
                      and craft <span class="font-weight-bold">efficient and precise Splunk queries</span> in extensive volume indexes.
                      Notably, the whole Splunk things I built for one former employer continues to generate value, a source of considerable pride.
                      Here are a few <span class="font-weight-bold">swift Splunk query tips</span> from one of my presentation: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/splunk/query_tips.md" target="_blank">https://gitee.com/x2c/tmp/blob/main/splunk/query_tips.md</a>
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Fluent in Python scripting:</span> As of now Python is the most comfortable tool for me to accomplish my daily tasks, especially for data processing requirements.
                      Have successfully crafted a simple web application for a friend to manage customer transaction records by using FastAPI scaffold <a class="blue-grey--text text--lighten-1" href="https://github.com/tiangolo/full-stack-fastapi-postgresql" target="_blank">full-stack-fastapi-postgresql</a>,
                      and I've made small contributions to Java/React based internal systems in several segments of my career, but I will not say I am familiar with web development.
                      Here is <span class="font-weight-bold">one sample script</span> (redacted) I recently authored at work: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/python/get_alert_triggers/src/main.py" target="_blank">https://gitee.com/x2c/tmp/blob/main/python/get_alert_triggers/src/main.py</a>
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Familar with Kubernetes/Istio:</span> Good understanding of containerlization and service mesh technology, with practical involvement in <span class="font-weight-bold">runinng production Splunk Cluster in managed Kubernetes(GKE)</span>,
                      hands-on experiences of Istio-enabled production EKS. While I have experimented with kopf (python kubernetes operator framework) and k8s client-go, have yet made any serious Operator.
                      By the way, I'm always enthustic to acquire new knowledge, aim to elevate my proficiency in Go this year.
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold green--text text--lighten-1" style="font-size: 1.6rem;">
                    - Excellent troubleshooting skill:</span> at user journey level (biliions of users and vast, intricate enviorment), at network level (dns, ip/tcp, tls, mtls, certificate, http, istio, container, grpc), at dependency middleware level (Kafka, workflow engine), or at codebase, CI/CD, infra-as-code, configuration-as-code level.
                  </div>
                </v-flex>

                <v-flex>
                  <div class="mb-0"> <span class="font-weight-bold blue-grey--text text--lighten-2" style="font-size: 1.6rem;">
                   - Limitation:</span> limited development experience (never LeetCode), <a class="blue-grey--text text--lighten0" href="https://cert.efset.org/yY3Goy" target="_blank">B2 Upper Intermediate english level</a>, sometimes spend too much time to explor details and principles, too much to tell...
                  </div>
                </v-flex>

                </div>
              </v-card-title>
            </v-card>
          </v-flex>


          <v-flex xs12>
            <v-card style="word-break: break-word;">
              <v-card-title primary-title body-2>

                <div style="line-height: 1.25 !important">

                <v-flex class="mb-1 pb-0 mt-0 pt-0">
                  <h3 class="headline mb-0 pb-0 blue--text">Experiences</h3>
                </v-flex>

                <v-flex>
                    <div class="mb-2">
                      <span class="font-weight-bold deep-purple--text text--darken-4" style="font-size: 1.6rem;">Site Reliability Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://www.quest-global.com/" target="_blank">Apple Pay (AWF)</a> 2022.12 - </span>
                    </div>

                    <ul>
                      <li class="mb-2">
                    <div > Lead engineer at Quest Global, onsite vendor in Apple Pay APAC SRE team.
                      Work in a great engineering quality and top-tier scale environment, where tasks are fragement.
                    </div>
                      </li>

                      <li class="mb-2">
                    <div class="mb-1">
                      A significant portion of my responsibilities is addressing production issue, 
                      very challenging to derive accurate impact numbers by crafting Splunk query within extensive logs volume along with complex retry behaviours across internal and external partners.
                      Fortunately, my proficiency in this area has enabled me to <span class="font-weight-bold">elevate the Splunk skills of the entire team to a new level</span>.
                    </div>
                      </li>

                      <li class="mb-2">
                    <div class="mb-1">
                       The second part involves utilizing scripts to analyze or process data from various internal systems. 
                        In most cases, this remains part of addressing production issues, while at times, 
                        it serves a more <span class="font-weight-bold">tool-oriented purpose</span>(e.g. <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/python/gen_aws_kube_config/src/app/aws_profiles.py" target="_blank">https://gitee.com/x2c/tmp/blob/main/python/gen_aws_kube_config/src/app/aws_profiles.py</a>).
                    </div>
                      </li>

                    </ul>
                </v-flex>

                <v-flex >
                    <div class="mb-2">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb1" style="font-size: 1.6rem;">Site Reliability Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://stubhub.com" target="_blank">Stubhub Shanghai Imformation Technology Ltd</a> 2020.8 - 2022.8</span>
                    </div>

                    <ul>
                      <li class="mb-2">
                    <div class="mb-1">A leading industry company with approximately 1,000 employees. 
                      As a result of separation from the parent company, eBay, we need to migrate all services from eBay's DC to GCP.
                      I own the Splunk service, have seamlessly <span class="font-weight-bold">transitioned the Splunk cluster to GKE</span>,
                      furnishing the entire organization with logging, alerting, and dashbord capabilities.
                      The daily data volume peaks at a maximum of 5TB.
                      </div>
                      </li>

                      <li class="mb-2">
                        <div class="mb-1">
                          Also help to maintain many other internal systems, and implement integrations. 3k+ hosts (vCenter -> GCP -> Azure), inherit from eBay technology architecture (Akamai, stage/dev/prod/wall garden env, 3 pods + F5 VIP, puppet/terraform/ansible/jenkins/nexus/self-hosted yum repo/self-built CICD platform, nginx + stateless spring boot + react + spring batch + oracle + kafka + solr + nfs, Jira, Slack, NewRelic, PagerDuty)
                        </div>
                      </li>
                    </ul>
                </v-flex>

                <v-flex >
                    <div class="mb-1">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb1" style="font-size: 1.6rem;">
                      Operations Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="https://techown.com" target="_blank">Shanghai Techown Imformation Technology Ltd</a> 2019.4 - 2020.8</span>
                    </div>

                    <ul>
                      <li class="mb-2">
                    <div class="mb-1">
                      A small company with approximately 100 employees.
                        To address the pain points of inefficient data processing and alerting gaps, I built a Splunk cluster from ground up,  
                        <span class="font-weight-bold">created over 30 interactive dashboards and many alerts to report key business metrics, 
                        developed a third-party app for DingDing alert,
                        authored several scripts and long-running processes</span> to feed customer needs and optimizing the entire data processing workflow.
                    </div>
                      </li>

                    <li class="mb-1">
                      <div class="mb-1">
                        Here's <span class="font-weight-bold">a slides</span> to describe the overall process: <a class="blue--text text--darken-1" href="https://gitee.com/x2c/tmp/blob/main/slides/A_slide.key" target="_blank">https://gitee.com/x2c/tmp/blob/main/slides/A_slide.key</a>
                      </div>
                    </li>

                      <li class="mb-1">
                      <div class="mb-1">
                        Here's <span class="font-weight-bold">a sample dashboard</span> I made: <a class="blue--text text--darken-1" href="https://i.loli.net/2020/07/13/1sqizeWZkE35cFB.png" target="_blank">https://i.loli.net/2020/07/13/1sqizeWZkE35cFB.png</a>
                      </div>
                      </li>
                    </ul>
                </v-flex>

                <v-flex >
                    <div class="mb-1">
                    <span class="font-weight-bold deep-purple--text text--darken-4 mb5" style="font-size: 1.6rem;">
                      Operations Engineer <a class="font-weight-bold blue-grey--text text--darken-2" href="http://www.houhao.cn" target="_blank">several modestly sized companies</a> 2016.3 - 2019.4</span>
                    </div>

                    <ul>
                      <li class="mb-2">
                    <div class="mb-1">
                      During the initial three years of my career, I consistently operated independently within several modestly sized companies.
                        Most of those jobs are in traditional Ops engineer's scope, including hosts and services management. Highlighted below are <span class="font-weight-bold">some major achievements</span>.
                    </div>

                      </li>

                      <li class="mb-2">
                    <div class="mb-1">
                      For a SaaS web product, achieved a <span class="font-weight-bold">30% cost reduction</span> in AlibabaCloud by moving services to docker and run in less VMs,
                        including nodejs, redis, mongo, mysql, java, and .Net services.
                        Also <span class="font-weight-bold">introduced HA capability</span> into production: 
                        nginx+keepalived LB, keepalived + redis(master slave+sentinel), mongo(replica set), mysql(dual master),
                        migrate deployment runbook from mannully to shell to ansible to docker.
                    </div>

                      </li>

                      <li class="mb-1">
                    <div class="mb-1">
                      For a startup, <span class="font-weight-bold">established the entire office IT infrastructure from zero, encompassing both hardware and software</span>.
                        Managed the procurement and configuration of Router, Switch, Server, Server Rack, Telephonic Switching System, Landline Telephone, and Laptops.
                        Design network, <span class="font-weight-bold">setup Windows Domain</span> with Domain Controller, WDS+MDT, CIFS, WSUS, CA, and VPN.
                        <span class="font-weight-bold">Configure pfSense</span> to govern the network for servers in the IDC, 
                        established site-to-site OpenVPN links to the office network, <span class="font-weight-bold">implemented Zabbix</span> for comprehensive OS and hardware monitoring.
                    </div>
                    </li>
                    </ul>

                </v-flex>
              
                </div>
              </v-card-title>
            </v-card>
          </v-flex>

          <v-flex xs12>
            <v-card color="cyan darken-2" class="white--text ">
              <v-card-title primary-title body-2>
                <div class="d-flex headline mb-1">Education</div>
                <div>
                      <div class="mb-1"> - 2012 - 2016, Shanghai Polytechnic University, majored in Electronic Commerce</div>
                      <div class="mb-1"> - The majority of courses are about constructing e-commerce website utilizing Java,
                        employing somewhat outdated technologies such as Java 1.6, Spring MVC, Struts2, and Hibernate.</div>
                      <div class="mb-1"> - I possess a keen interest in exploring those <span class="font-weight-bold text--darken-2">dummy hack stuffs</span>, 
                        such as building minimal Windows images under 100MB, utilizing Linux desktop including Kali, 
                        constructing minimal Android ROMs with only several startup processes, and engaging in iOS jailbreaking.
                      </div>
                </div>
              </v-card-title>
              <v-divider light></v-divider>
              <v-card-actions class="pa-3">
                <div>
                  Appreciate your time to review this resume, an online version is also avaliable in <a href="https://i.ch2n.com/#/en">https://i.ch2n.com/#/en</a>
                </div>
                <v-spacer></v-spacer>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
                <v-icon>star_border</v-icon>
              </v-card-actions>
            </v-card>
          </v-flex>

        </v-layout>

      </v-container>
    </v-card>

  </v-app>
  </div>
</template>

<script>
export default {
  name: 'En',
  components: {
  }
}
</script>

<style scoped>
a {
  color: #424242;
}
body {
    /*en,zh,mac,windows,linux,ios,android*/
    font: 14px/1.5 Arial,Helvetica,"Lucida Grande","Helvetica Neue",'PingFang SC',"Hiragino Sans GB","Hiragino Sans GB W3","Microsoft YaHei","WenQuanYi Microhei","Heiti SC","STHeiti","Noto Sans CJK SC","Source Han Sans CN",sans-serif; 
}
</style>
